/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li",
    br: "br",
    h3: "h3"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, SideBySide, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/brands/widex/'], ['en', 'https://www.hear.com/hearing-aids/brands/widex/'], ['en-US', 'https://www.hear.com/hearing-aids/brands/widex/'], ['en-CA', 'https://ca.hear.com/hearing-aids/brands/widex/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "find-your-widex-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#find-your-widex-hearing-aid",
    "aria-label": "find your widex hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Find Your Widex Hearing Aid"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With 4,000 employees around the world, Widex is one of the six major ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/",
    className: "c-md-a"
  }, "hearing aid brands"), " in the industry today. The company has built a reputation for innovation and reliability. Widex hearing aids are sold in over 100 countries worldwide. Based in Denmark, Widex operates production facilities in Denmark and Estonia. At Widex, we believe that the best hearing experience comes from natural sound. That's why we strive to make our hearing aid processing as close to the natural hearing system as possible, ensuring a truly natural sound."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With Widex hearing aids, you gain access to a hearing experience so authentic that you might forget you have a hearing loss. Here’s how we achieve it."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "history-of-innovation-and-reliability-with-widex",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#history-of-innovation-and-reliability-with-widex",
    "aria-label": "history of innovation and reliability with widex permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "History of Innovation and Reliability with Widex"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Christian Topholm and Erik Westermann founded Widex in 1956 in a Denmark basement. The first Widex hearing aid fit in a pocket. In 1988, the company introduced its first digitally ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "programmable hearing aid"), ". The first digital in-the-ear hearing aid came out in 1995."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "1990 saw a significant advance with the start of computerized manufacturing of individual hearing aid shells. This process allowed for more precise modeling processes, making hearing aids fit more precisely."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Widex recognized in 2009 that people with hearing loss need accessories to help them hear phones and televisions better. The company introduced its Dex line of assistive listening technology."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In 2019, Widex merged with Sivantos to form WS Audiology. For now, both hearing aid divisions operate with separate sales and manufacturing branches."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-widex-philosophy",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-widex-philosophy",
    "aria-label": "the widex philosophy permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Widex Philosophy"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Widex operates according to six core values:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Customers First: They make sure that their processes and technology serve customers."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Pioneers: They are innovative engineers who look for new solutions to the problem of hearing loss."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Reliable: They create hearing aids that last and follow through on their promises."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Team Spirit: They work in collaborative teams."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Passion: They are passionate about helping the hearing-impaired."), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The company has put their core values in action in constructing their eco-friendly headquarters building in Denmark. The building utilizes groundwater for heating and cooling and operates an energy-generating turbine."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When designing new hearing aid models and sound processing strategies, Widex engineers work to create the signature Widex Sound. Widex Sound strives to provide customers with a full spectrum of sound. Customers should hear soft sounds, while loud sounds remain comfortable. The sound quality should be natural."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "most-advanced-widex-technology-options",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#most-advanced-widex-technology-options",
    "aria-label": "most advanced widex technology options permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Most Advanced Widex Technology Options"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/widex-smart-ric.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Widex product lines include the Widex SmartRIC, Widex Moment Sheer, Widex Moment and Widex Evoke hearing aids. These hearing aids have a 108-dB dynamic range making them capable of delivering a wide spectrum of sound to your ear. Widex hearing aids offer the purest and most natural sound possible for wearers.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "In 2024, Widex introduced the Widex SmartRIC hearing aid that is elevated on the ear and engineered for improved microphone angles, improved battery life, and is designed for comfort on the ear.  This hearing aid incorporates features such as machine learning, Zen and Widex SoundRelax TM tinnitus options, and cell phone streaming capabilities. Over time the hearing aid learns your listening preferences. This process reduces the need for the trial-and-error process of fine-tuning hearing aids in the hearing care professional’s office.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have difficulty hearing on the phone or listening to television, Widex offers the Dex line of accessories to stream sound from audio sources directly into your hearing aids. Widex offers hearing aid models in a range from basic to premium technology. Your hearing care professional can help you select the right hearing aid for your needs and budget. If you need your hearing aid programs adjusted, you can ask your hearing care professional to send you new programs remotely. The Widex Remote Care feature works with all Widex wireless hearing aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "your-partners-in-hearing-health",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#your-partners-in-hearing-health",
    "aria-label": "your partners in hearing health permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Your Partners in Hearing Health"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we believe in the power of hearing. We want to help you connect to your friends and family. Register on our website, and one of our experts will call you to discuss your hearing concerns and questions. We will refer you to a local hearing care professional for an in-depth hearing evaluation."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you need hearing aids, the professional will fit the hearing aids and provide follow-up visits. Your hear.com expert will keep tabs on your progress throughout your journey. You can try the hearing aids for 45 days risk-free. If you are not satisfied, return the hearing aids for a full refund."), "\n", React.createElement(LandingPageCta, {
    copy: "Try Widex hearing aids now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "widex-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#widex-hearing-aids",
    "aria-label": "widex hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Widex hearing aids"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/widex-smart-ric.png"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "widex-smartric",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#widex-smartric",
    "aria-label": "widex smartric permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Widex SmartRIC"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Feel good, hear better. All day long. Its unique L-shaped design positions it higher on your ear, enhancing your ability to hear conversations clearly even in noisy environments. The hearing aid long-lasting power battery provides up to 37 hours of use. It also includes a portable charger with enough capacity for over a week of on-the-go charging.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/widex-moment-sheer-sric-hearing-aid.png"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "widex-moment-sheer",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#widex-moment-sheer",
    "aria-label": "widex moment sheer permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Widex Moment Sheer"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Introducing our most elegant RIC yet, the Moment Sheer sRIC R D hearing aid, built around the Widex Moment™ platform and the revolutionary PureSound™. This advanced device is suitable for a range of hearing loss from minimal to severe to profound. It features distinct new microphone openings for a fresh visual look, is fully rechargeable, and offers full connectivity. With a protection class of IP68, it boasts a battery life of 29 hours without streaming or 16 hours with 8 hours of streaming.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/widex-moment-hearing-aid.png"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "widex-moment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#widex-moment",
    "aria-label": "widex moment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Widex Moment"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Experience natural sound like never before with the WIDEX MOMENT™. Traditionally, even the best hearing aids have had a distinctive sound, but the revolutionary Widex Moment changes that, delivering the purest, most natural sound ever. Typically, sound processed in a hearing aid reaches the eardrum later than direct sound, creating an artificial effect when these 'out of sync' signals mix. With the Widex Moment, you can hear every moment as you used to, thanks to our innovative PureSound™ technology.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/widex-evoke.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "widex-evoke",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#widex-evoke",
    "aria-label": "widex evoke permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Widex Evoke"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The smartest hearing aid on the market, the Widex Evoke, provides pristine sound quality while simultaneously adjusting to your current environment. Using SoundSense Adapt technology, this hearing aid can change its settings automatically because it “learns” as it goes."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/widex/evoke/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(LandingPageCta, {
    copy: "Try Widex hearing aids now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
